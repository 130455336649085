/**
 * This file works as a config file due to the lack of CMS or config db.
 * ENABLED_PRODUCTS: array of current products (subs, club, unlimited), currently only used for power bi dashboards
 */

import appConstants from './app';

const { SUBS_PRODUCTS } = appConstants;

export default {
  Y4: {
    ENABLED_PRODUCTS: [SUBS_PRODUCTS.SUBSCRIPTION, SUBS_PRODUCTS.UNLIMITED, SUBS_PRODUCTS.CLUB],
  },
  KZR: {
    ENABLED_PRODUCTS: [SUBS_PRODUCTS.CLUB],
  },
  SV: {
    ENABLED_PRODUCTS: [SUBS_PRODUCTS.SUBSCRIPTION],
  },
  F3: {
    ENABLED_PRODUCTS: [SUBS_PRODUCTS.SUBSCRIPTION],
  },
  JA: {
    ENABLED_PRODUCTS: [SUBS_PRODUCTS.SUBSCRIPTION],
  },
  W6: {
    ENABLED_PRODUCTS: [SUBS_PRODUCTS.SUBSCRIPTION, SUBS_PRODUCTS.UNLIMITED],
  },
  AV: {
    ENABLED_PRODUCTS: [SUBS_PRODUCTS.CLUB],
  },
  J9: {
    ENABLED_PRODUCTS: [SUBS_PRODUCTS.CLUB],
  },
};
