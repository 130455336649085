import appConstants from '@/constants/app';

const { TENANT, ROLES } = appConstants;

const allowedTenants = [
  TENANT.TZ,
  TENANT.AXA,
  TENANT.WO,
  TENANT.EDO,
  TENANT.CVO,
  TENANT.VH,
  TENANT['4U'],
  TENANT.D7,
  TENANT.HR,
  TENANT.FA,
  TENANT.Y4,
  TENANT.LA,
  TENANT['0B'],
  TENANT.AS,
  TENANT.AV,
  TENANT.W6,
  TENANT.J9,
  TENANT.KZR,
  TENANT.SV,
  TENANT.F3,
  TENANT.JA,
];

const allowedRoles = [
  ROLES.SUPER,
  ROLES.VIEWER,
  ROLES.ADMIN,
];

/**
 * Checks if the given tenant has access to reports
 *
 * @param {string} tenant - The tenant code to check
 * @returns {boolean}
 */
export default function hasAccessToReports(tenant, userRole) {
  return allowedTenants.includes(tenant) && allowedRoles.includes(userRole);
}
